<template>
  <div class="modal" id="lenderFilter">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Select filter</h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="form-group mx-2">
            <label for="searchField">
              <input
                type="text"
                id="searchField"
                class="form-control"
                v-model="query"
                @input="searchTimeOut"
                ref="searchField"
                placeholder="Search"
              />
            </label>
          </div>
          <div class="page-filtration__filters">
            <button
              v-for="tag in tags"
              :key="'tag_' + tag.id"
              @click="activateTag($event, tag)"
              class="page-filtration__filter"
              :class="{'page-filtration__filter_active': isSelected(tag.name), default: tag.id === 0 || !isGranted('ROLE_CEO_ASSISTANT')}">
              {{ tag.name }}
              <span v-if="tag.id > 0 && !isSelected(tag.name) && isGranted('ROLE_CEO_ASSISTANT')" @click="deleteTag(tag.id)">
                <img src="@/assets/icons/icon-status-error.svg" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <base-button title="Apply" action="secondary-default" @click-btn="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue';
export default {
  components: { BaseButton },
  name: "LenderFilters",
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    selectedTags: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      query: "",
      currentTag: this.selectedTags,
      timer: null,
    };
  },
  methods: {
    activateTag(event, tag) {
      if (event.target.nodeName.toLowerCase() === "button") {
        if (tag.name !== 'All') {
          if (this.currentTag.find((el) => el.name === 'All')) {
            this.currentTag = this.currentTag.filter((el) => el.name !== 'All')
          }
          if (this.currentTag.find((el) => el === tag)) {
            this.currentTag = this.currentTag.filter((el) => el.name !== tag.name)
          } else {
            this.currentTag.push(tag)
          }
        } else {
          this.currentTag = [
            {
              id: 0,
              name: "All"
            }
          ]
        }

        this.$emit('update-filter', {query: this.query, tags: this.currentTag })
      }
    },
    isSelected(tagName = null) {
      return this.currentTag.filter((el) => el.name === tagName).length !== 0
    },
    deleteTag(id) {
      if (confirm("Are you sure?")) {
        this.ui.isLoading = true;
        this.$http
          .delete(`/api/v1/tag/${id}/delete`)
          .then(() => this.getTags())
          .catch(err => {
            this.alertError(err.response.statusText);
            this.ui.isLoading = false;
          });
      }
    },
    searchTags() {
      this.tags = this.tags.filter(tag =>
        tag.name.toLowerCase().includes(this.query.toLowerCase())
      );
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.searchTags()
        this.$emit('update-search', this.query.toLowerCase())
      }, 800);
    },
  }
};
</script>

<style lang="scss" scoped>
#lenderFilter {
  .modal-block {
    width: 500px;
    .form-group {
      input {
        border-radius: 16px;
        height: 32px;
        font-size: 13px;
        margin-bottom: 10px;
      }
    }
    .page-filtration__filter {
      font-size: 11px;
      padding: 5px 5px 5px 25px;
      &_active {
        padding: 5px 25px;
      }
    }
    .default {
      padding: 5px 10px;
    }
  }
}
</style>
