<template>
  <div class="lender cursor-pointer" @click="openPreview">
    <div class="lender__header">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center mb-2">
          <div class="form-group me-2">
            <input type="checkbox" class="form-check-input" v-model="lender.isChecked">
          </div>
          <h4 class="lender__title">{{ lender.name }}</h4>
        </div>
        <div class="d-flex align-items-center">
          <button
            class="btn btn-control lender__button mx-2"
            @click="$emit('note-form', lender.id)"
          >
            <img src="@/assets/icons/icon-notes.svg" alt="note" />
          </button>
          <button
            class="btn btn-control lender__button"
            v-if="lender.isGranted.update"
            @click="$emit('lender-form', lender.id)"
            >
            <img src="@/assets/icons/icon-edit-normal.svg" alt="Edit" />
          </button>
        </div>
      </div>
      <div class="lender__labels">
        <div class="lender__label" v-for="tag in lender.tags" :key="tag.id">
          {{ tag.name }}
        </div>
      </div>
    </div>
    <div class="lender__body">
      <div class="lender__name">{{ lender.repName }}</div>
      <a :href="`tel:${lender.repPhone}`" class="lender__phone hover-link">
        {{ lender.repPhone }}
      </a>
      <a :href="`mailto:${lender.repEmail}`" class="lender__email hover-link">
        {{ lender.repEmail }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "LendersCard",
  props: {
    lender: {
      type: Object
    }
  },
  methods: {
    openPreview(event) {
      if (event.target.nodeName.toLowerCase() !== "a" && event.target.nodeName.toLowerCase() !== 'input') {
        this.$emit("details", this.lender.id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hover-link {
  display: inline-block;
  color: #000;
  text-decoration: none;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: #0078ff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: #0078ff !important;
    &::after {
      width: 100%;
    }
  }
}

.lender {
  border: 1px solid #dadada;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 24px 49px 24px 24px;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.07);

    .lender__button {
      opacity: 1;
      visibility: visible;
    }
  }

  &__header {
    padding-bottom: 11.5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  &__button {
    opacity: 0;
    transition: all 0.3s;
    visibility: hidden;
  }

  &__labels {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__label {
    padding: 3px 9px;
    color: #4c4c4c;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    border-radius: 2px;
    background-color: rgba(146, 146, 146, 0.1);
    margin-right: 4px;
    margin-bottom: 4px;
  }

  &__title {
    width: fit-content;
    color: #0056ff;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    cursor: pointer;
    transition: all 0.3s;
    margin-bottom: 0;
    &:after {
      display: block;
      content: "";
      width: 0;
      height: 1px;
      background: #0056ff;
      transition: all 0.3s;
    }

    &:hover {
      color: #0050ee;

      &::after {
        width: 100%;
      }
    }
  }

  &__title,
  &__name,
  &__email,
  &__phone {
    letter-spacing: 0;
    width: fit-content;
  }

  &__name {
    font-size: 14px;
    line-height: 21px;
    margin: 11.5px 0 8px 0;
  }

  &__phone,
  &__email {
    display: block;
    color: rgba(0, 0, 0, 0.7);
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 8px;
  }
}
.form-check-input {
  height: 18px;
  max-width: 18px;
  margin: 0;
  cursor: pointer;

  &:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
}
</style>
