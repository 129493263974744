<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Request New Lender</h3>
        <button class="btn btn-action" @click="$emit('close')">
          <img src="@/assets/icons/icon-close.svg" alt="" />
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="row">
          <iframe
            id="JotFormIFrame-221306489700049"
            title="New Investor Request Form"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allowfullscreen="true"
            allow="geolocation; microphone; camera"
            src=https://form.jotform.com/221306489700049
            frameborder="0"
            style="
            min-width: 100%;
            height:90vh;
            border:none; 
            padding:4.5rem 2rem 2rem 2rem;">
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "RequestLenderModal",
  props: {},
}
</script>

<style lang="scss" scoped>
.modal.modal-center {
  .modal-block {
    margin: 3vh auto 3vh auto;
    overflow-y: auto;
    border-radius: 8px;
  }
  .modal-footer {
    bottom: unset;
  }
  label {
    margin-bottom: .3rem;
  }
  input, textarea {
    background-color: #F3F3F4;
  }
}
</style>
