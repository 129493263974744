<template>
  <div>
    <!--  Page Header  -->
    <base-page-header title="Lenders">
      <template #buttons>
        <div class="d-flex justify-content-md-end">
          <a
            href="https://www.lendersearch.com"
            class="base-button temp"
            content="Find a lender for a niche product you don’t see here"
            v-tippy="{placement: 'bottom', arrow: true,arrowType: 'round'}"
            target="_blank">
              Find a Lender
          </a>
          <a
            :href="`mailto:${lendersEmails.join(';')}`"
            class="base-button temp text-decoration-none"
            content="Send a blast email to active lenders"
            v-tippy="{placement: 'bottom', arrow: true,arrowType: 'round'}"
            target="_blank"
            :class="{disabled: lendersEmails.length === 0}"
            v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT')">Blast Email
          </a>
          <base-button title="Scenario Wizard" action="secondary" @click-btn="ui.isBlastScenario = true"
                       content="Send a blast email to multiple lenders to review a scenario"
                       v-tippy="{placement: 'bottom', arrow: true, arrowType: 'round'}" class="mx-3" />
          <base-button title="Request New Lender" action="secondary" @click-btn="requestNewLender"
                       content="Ask CMC to sign up with a new lender"
                        v-tippy="{placement: 'bottom', arrow: true, arrowType: 'round'}" />
          <base-button title="Add New Lender" action="primary" @click-btn="openLenderForm({})"
                       class="mx-3" />
          <base-button-dropdown title="Export" dropdownName="exportDropdown" action="secondary" v-if="activeTab === 'Active'"
                                :loading="ui.exportLoading" @click-btn="exportCall" :disabled="ui.exportLoading || ui.isLoading">
            <template #body>
              <div class="col-12">
                <div class="row p-1 cursor-pointer" @click="ui.exportFilter = true">
                  <div class="col-2">
                    <img src="@/assets/icons/download.svg" alt="" />
                  </div>
                  <div class="col-10 text-start my-auto">
                    <span>Download Report</span>
                  </div>
                </div>
              </div>
            </template>
          </base-button-dropdown>
        </div>
      </template>
    </base-page-header>

    <!--  Page Tabs  -->
    <div class="d-flex page-filters">
      <div class="d-flex align-items-baseline">
        <base-tabs :tabs="tabs" :active-tab="activeTab" @set-tab="toggleTab" class="pe-0" />
        <div class="form-group mx-2 search-field" id="search-field">
          <label for="searchField">
            <input
              type="text"
              id="searchField"
              class="form-control"
              v-model="searchQuery"
              @input="updateQuery(searchQuery)"
              ref="searchField"
              placeholder="Search"
            />
          </label>
        </div>

      </div>

      <div class="d-flex justify-content-end">
        <base-button title="DSCR Lender Licensing" action="temp" @click-btn="ui.showFilterByStateModal = true" />
        <base-button title="Filters" action="temp" @click-btn="ui.showFilterModal = true" class="mx-4" />
      </div>
    </div>


    <!--  Page Content  -->
    <base-page-content>
      <template #content>
        <page-spinner v-if="ui.isLoading" />
        <div class="lender-cards-wrapper mt-5" v-else>
          <lenders-card
            :lender="lender"
            v-for="(lender, index) in searchLenders"
            :key="index"
            @details="openLenderDetails"
            @lender-form="openLenderForm"
            @note-form="openNoteForm"/>
        </div>
        <div class="modal-alert modal-alert_advice text-center" v-if="searchLenders.length === 0 && !ui.isLoading">
          No Results Found
        </div>
      </template>
    </base-page-content>

    <!--  Modals  -->
    <transition name="slide-fade">
      <LenderDetails
        v-if="showLenderDetails"
        :id="selectedLenderId"
        @close="closeLenderDetails"
        @editLender="openLenderForm"
      />
    </transition>
    <transition name="slide-fade">
      <LenderForm
        v-if="ui.lenderForm && selectedLenderId"
        :id="selectedLenderId"
        @close="closeLenderForm"
      />
    </transition>
    <transition name="slide-fade">
      <NoteForm
        v-if="ui.noteForm && selectedLenderId"
        :id="selectedLenderId"
        @close="closeNoteForm"
        @add-lender-note="openAddLenderNoteModal"
      />
    </transition>
    <transition name="slide-fade">
      <AddNoteForm
        v-if="ui.addNoteForm && selectedLenderId"
        :id="selectedLenderId"
        :notes="selectedNote"
        @close="closeAddLenderNoteModal"
      />
    </transition>
    <transition name="slide-fade">
      <blast-scenario
        v-if="ui.isBlastScenario"
        :lenders="activeLenders"
        :states="states"
        @close="ui.isBlastScenario = false"
      />
    </transition>

    <transition name="component-fade" mode="out-in">
      <export
        v-if="ui.exportFilter"
        @close="ui.exportFilter = false"/>
    </transition>

    <transition name="component-fade" mode="out-in">
      <RequestLenderModal
        v-if="ui.requestNewLender"
        @close="ui.requestNewLender=false"
      />
    </transition>

    <transition name="component-fade" mode="out-in">
      <FilterByStateModal
        v-if="ui.showFilterByStateModal"
        @close="ui.showFilterByStateModal=false"
        @openLender="openLenderDetails"
      />
    </transition>

    <transition name="component-fade" mode="out-in">
      <lender-filters :tags="tags" @update-filter="updateSearchQuery" @update-search="updateQuery"
                       v-if="ui.showFilterModal" @close="ui.showFilterModal = false" :selected-tags="currentTag" />
    </transition>
  </div>
</template>

<script>
import LenderDetails from "./LenderDetails";
import LenderForm from "./LenderForm";
import NoteForm from "./NoteForm";
import AddNoteForm from "./AddNoteForm";
import BasePageHeader from "../../components/BasePageHeader";
import BaseTabs from "../../components/BaseTabs";
import BasePageContent from "../../components/BasePageContent";
import PageSpinner from "../../components/pageSpinner";
import LendersCard from "./LendersCard";
import Export from "./modals/Export";
import BlastScenario from "./modals/BlastScenario";
import RequestLenderModal from "./RequestLenderModal";
import BaseButton from '../../components/BaseButton.vue';
import BaseButtonDropdown from '../../components/BaseButtonDropdown.vue';
import LenderFilters from './modals/LenderFilters.vue';
import FilterByStateModal from './FilterByStateModal';

export default {
  name: "Lenders",
  components: {
    FilterByStateModal,
    LendersCard,
    PageSpinner,
    BasePageContent,
    BaseTabs,
    BasePageHeader,
    LenderDetails,
    LenderForm,
    NoteForm,
    Export,
    BlastScenario,
    AddNoteForm,
    RequestLenderModal,
    BaseButton,
    BaseButtonDropdown,
    LenderFilters
  },
  data() {
    return {
      activeTab: "Active",
      lenders: [],
      states: [],
      activeLenders: [],
      inactiveLenders: [],
      selectedLenderId: null,
      selectedNote: null,
      query: "",
      timer: null,
      tabs: [{ title: "Active" }, { title: "Inactive" }],
      searchQuery: '',
      ui: {
        showSearchField: false,
        isBlastScenario: false,
        exportFilter: false,
        exportLoading: false,
        lenderForm: false,
        noteForm: false,
        addNoteForm: false,
        isLoading: false,
        requestNewLender: false,
        showFilterModal: false,
        showFilterByStateModal: false,
      },
      currentTag: [
        {
          id: 0,
          name: "All"
        }
      ],
      tags: []
    };
  },
  methods: {
    getTags() {
      this.ui.isLoading = true;
      this.$http.get(`/api/v1/tags`).then(res => {
        this.getLenders();
        res.data.unshift({
          id: 0,
          name: "All"
        });
        this.tags = res.data;
      });
    },
    getLenders() {
      this.ui.isLoading = true;
      this.$http
        .get("api/v1/lenders")
        .then(res => {
          this.activeLenders = res.data.activeLenders;
          this.inactiveLenders = res.data.inactiveLenders;
          this.states = res.data.states;
          this.lenders =
            this.activeTab === "Active"
              ? this.activeLenders
              : this.inactiveLenders;

          this.lenders = this.lenders.map(el => {
            el.isChecked = false;
            return el;
          })
        })
        .finally(() => {
          this.ui.isLoading = false;
        });
    },
    toggleTab(tabName) {
      this.activeTab = tabName.title;
      this.lenders = this.activeTab === "Active" ? this.activeLenders : this.inactiveLenders;
    },
    openLenderDetails(id) {
      this.selectedLenderId = id;
    },
    closeLenderDetails(updateData) {
      updateData ? this.getTags() : null;
      this.selectedLenderId = null;
    },
    openLenderForm(id) {
      this.ui.lenderForm = true;
      this.selectedLenderId = id;
    },
    closeLenderForm(updateData) {
      console.log(123)
      updateData ? this.getTags() : null;
      this.ui.lenderForm = false;
      this.selectedLenderId = null;
    },
    openNoteForm(id) {
      this.ui.noteForm = true;
      this.selectedLenderId = id;
    },
    closeNoteForm() {
      this.ui.noteForm = false;
      this.selectedLenderId = null;
    },
    openAddLenderNoteModal(id, note) {
      this.ui.addNoteForm = true;
      this.selectedLenderId = id;
      this.selectedNote = note;
    },
    closeAddLenderNoteModal(force = false) {
      this.ui.addNoteForm = false;
      this.selectedLenderId = null;
      this.selectedNote = null;
      if (force) {
        this.closeNoteForm();
      }
    },
    exportCall() {
      this.ui.exportLoading = true;
      this.$http
        .get(`/api/v1/lenders/export`)
        .then(res => {
          if (res.data) {
            let fileURL = window.URL.createObjectURL(new Blob([res.data])),
              fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", "Lender-Partners.csv");
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
          }
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.exportLoading = false;
        });
    },
    requestNewLender() {
      this.ui.requestNewLender = true
    },
    updateSearchQuery({query, tags}) {
      this.query = query
      this.currentTag = tags
    },
    updateQuery(query) {
      this.query = query.toLowerCase()
    }
  },
  computed: {
    lendersEmails() {
      return this.activeLenders.filter((lender) => this.validateEmail(lender.repEmail)).map((lender) => {
        return lender.repEmail;
      });
    },
    showLenderDetails() {
      if (this.ui.lenderForm) {
        return false;
      }

      if (this.ui.noteForm) {
        return false;
      }

      if (this.ui.addNoteForm) {
        return false;
      }

      if (!this.selectedLenderId) {
        return false;
      }

      return true;
    },
    searchLenders() {
      let lendersWithTag = [], lenders = [];

      if (this.currentTag.length > 0 && this.currentTag[0].name !== 'All') {
        let baselenders = this.lenders
          .filter(lender => {
            let isExist = lender.tags.findIndex(lenderTag => {
              if (this.currentTag.some(tag => tag.id === lenderTag.id) || this.currentTag[0].name === 'All') {
                return true;
              }
            });

            if (isExist > -1) return true;
          });

        lenders = baselenders.filter(lender =>
          lender.name.toLowerCase().includes(this.query.toLowerCase())
        );

        if (this.query.toLowerCase()) {
          lendersWithTag = baselenders.filter(lender => lender.tags.filter(tag => tag.name.toLowerCase().includes(this.query.toLowerCase())).length);
        }
      } else {
        lenders = this.lenders.filter(lender =>
          lender.name.toLowerCase().includes(this.query.toLowerCase())
        );

        if (this.query.toLowerCase()) {
          lendersWithTag = this.lenders.filter(lender => lender.tags.filter(tag => tag.name.toLowerCase().includes(this.query.toLowerCase())).length);
        }
      }

      return [...lenders, ...lendersWithTag];
    }
  },
  mounted() {
    this.getTags();
  }
};
</script>

<style lang="scss">
.page-filtration {
  display: flex;
  padding: 15px 25px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  &__button {
    position: relative;
    z-index: 2;
    top: -3px;

    &.page-filtration__button_disable {
      cursor: none;
      pointer-events: none;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &__search {
    margin-top: 10px;
    position: relative;
    min-width: 20px;
    margin-right: 10px;

    .form-group {
      position: absolute;
      opacity: 0;
      z-index: -1;
      top: 0;
      width: 0;
      transition: all 0.3s;

      input {
        height: 34px;
        border-radius: 16px;
        padding-left: 35px;
      }
    }

    &_active {
      min-width: 160px;
      .form-group {
        z-index: 0;
        min-width: 160px;
        opacity: 1;
      }
    }
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      margin-left: 10px;
      padding-right: 20px;
    }
  }

  &__filter {
    height: fit-content;
    min-width: fit-content;
    border: 1px solid #cccccc;
    border-radius: 16px;
    background: white;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    padding: 7px 12px;
    transition: all 0.3s;
    margin: 10px 0 0 6px;
    padding-left: 30px;
    padding-right: 10px;

    &.page-filtration__filter_active {
      margin-top: 10px;
      background-color: #000000;
      color: #ffffff;
      padding: 7px 12px;
    }

    &.default {
      padding: 7px 12px;
    }

    span {
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
      margin-left: 2px;
      img {
        width: 15px;
      }
    }

    &:hover {
      span {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.page-header {
  padding-top: 12px;
    .blast-email {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.lender-cards-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  grid-gap: 30px;

  @media (max-width: 568px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.custom-dropdown {
  width: fit-content;
  margin-right: 1rem;
  display: flex;
  height: 40px;
  border: 1px solid #8a8a8a;
  border-radius: 8px 8px;

  &__btn-action {
    height: 100%;
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 11px;
    background: none;
    padding: 10px 19px;
    border-width: 0 1px 0 0;
    border-right: 1px solid #8a8a8a;
  }

  &__btn-trigger {
    height: 100%;
    background: none;
    border: none;
    padding: 0 7px;
  }

  &__triangle {
    width: 0;
    height: 0;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid transparent;
    border-top: 4.5px solid #000000;
  }

  &__body {
    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-size: 13px;
    }
  }
}

.filters {
  margin-top: -47px;
}

#search-field {
  input {
    border-radius: 16px;
    height: 32px;
    font-size: 13px;
    margin-bottom: 10px;
  }
}

.page-filters {
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #E7E8E8;
  .page-tabs {
    border-bottom: none;
  }
}
</style>
