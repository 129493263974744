<template>
  <!--  Page tabs  -->
  <div class="page-tabs d-flex justify-content-between">
    <ul class="tab-list">
      <li
        :class="{ active: tab.title === activeTab }"
        v-for="(tab, index) in tabs"
        :key="'tab_' + index"
        @click="$emit('set-tab', tab)"
      >
        {{ tab.title }}
        <span v-if="tab.count" class="tab-count">{{ tab.count }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BaseTabs",
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-count {
  display: flex;
  border-radius: 8px;
  background-color: #e6e6e6;
  color: rgba($color: #000000, $alpha: 0.7);
  font-size: 10px;
  letter-spacing: 0;
  line-height: 16px;
  padding: 1px 5.5px;
  margin-left: 4px;
  text-align: center;
}
</style>
