<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close', true)"></div>

    <div class="modal-block" v-show="loading === true">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-block" v-show="loading === false">
      <div class="modal-header">
        <h3>{{ notes ? "Edit Note" : "Add Note" }}</h3>
      </div>
      <div class="modal-body" id="modal-editNote-scroll">
        <div class="modal-body-container pb-2">
          <div class="row mb-4">
            <div class="col-md-12">
              <div class="form-group full-width">
                <label for="note">Disclosures Lender Sends</label>
                <textarea
                    type="url"
                    rows="5"
                    id="note"
                    placeholder="Lender Sends"
                    class="form-control full-width"
                    autocomplete="off"
                    v-model="note.lender_sends"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-12">
              <div class="form-group full-width">
                <label for="note">Disclosures CMC Sends</label>
                <textarea
                    type="url"
                    rows="5"
                    id="note"
                    placeholder="CMC Sends"
                    class="form-control full-width"
                    autocomplete="off"
                    v-model="note.cmc_sends"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-12">
                <div class="form-group full-width">
                    <label for="note">Note</label>
                    <textarea
                        type="url"
                        rows="5"
                        id="note"
                        placeholder="Note"
                        class="form-control full-width"
                        autocomplete="off"
                        v-model="note.note"
                        required
                    ></textarea>
                    <span
                      class="error-msg"
                      v-for="(error, index) in getError('note', errors)"
                      :key="index"
                    >
                      {{ error }}
                    </span>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-body-container border-0">
          <div class="modal-body-container_title">Attached Documents</div>
          <div
            class="attach-file mb-2"
            v-for="(doc, index) in note.docs"
            :key="index"
          >
            <div @click="downloadDoc(doc.clientFileName)" class="cursor-pointer">
              <img
                src="@/assets/icons/icon-attachment-18.svg"
                class="opacity-05"
                alt="Attach"
              />
              <span class="file-name">{{ doc.orignalFileName }}</span>
            </div>
            <img
              src="@/assets/icons/icon-delete.svg"
              class="cursor-pointer opacity-05"
              alt="Download"
              @click="deleteDoc(doc.clientFileName)"
            />
          </div>
          <div @click="selectDocs" @drag.prevent="getDocs" @dragover.prevent>
            <div class="scanned-doc position-relative text-center mt-3 cursor-pointer">
              <input
                type="file"
                id="docs"
                class="position-absolute"
                @change="getDocs"
                multiple
                hidden
              />
              <div class="scanned-doc-text" v-if="selectedDocsCount">
                <span>{{ selectedDocsCount }}</span> file(s) selected
              </div>
              <div class="scanned-doc-text" v-else>
                Drag File here or <span>Browse</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <base-button title="Save" action="secondary-default" @click-btn="storeNote" :disabled="submitting" :loading="submitting" />
        <base-button title="Discard" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import { getBackendUrl } from "@/utils/backendUrl";
import BaseButton from '../../components/BaseButton.vue';

export default {
  components: { BaseButton },
  name: "AddNoteForm",
  props: { id: { required: false }, notes: {required: false}},
  data() {
    return {
      formData: new FormData(),
      editMode: false,
      loading: true,
      submitting: false,
      note: {
        id: 0,
        lender_sends: "",
        cmc_sends: "",
        note: "",
        docs: [],
      },
      ui: {
        ps: null
      },
      selectedDocsCount: 0,
      errors: []
    };
  },
  methods: {
    storeNote() {
      this.submitting = true;
      this.errors = [];

      let note = this.note;

      for (let key in note) {
        if (key !== "docs") {
          this.formData.append(key, note[key]);
        }
      }

      let url = `/api/v1/lender/notes/${this.notes ? 'edit/' + this.note.id : 'new/' + this.id}`;
      this.$http
        .post(url, this.formData)
        .then((res) => {
          this.alertSuccess(res.data.message);
          this.$emit("close");
        })
        .finally(() => (this.submitting = false))
        .catch(err => {
          let errorMessage = err.response.statusText;
          if (err.response.status === 400) {
            this.formData = new FormData()
            errorMessage = err.response.data.message
            this.errors = err.response.data.errors;
          }

          this.alertError(errorMessage)
        });
    },
    getLenderNote() {
        if (this.notes) {
            this.note.id = this.notes.id;
            this.note.lender_sends = this.notes.lender_sends;
            this.note.cmc_sends = this.notes.cmc_sends;
            this.note.note = this.notes.note;
            this.note.docs = this.notes.docs;
        }
        this.loading = false;
    },
    getDocs(e) {
      let length = e.target.files.length;
      for (let i = 0; i < length; i++) {
        this.formData.append("docs[]", e.target.files[i]);
      }
      this.selectedDocsCount = length;
    },
    deleteDoc(fileName) {
      if (confirm("Are you sure?")) {
        let data = new FormData();
        data.append('fileName', fileName);
        this.$http.post(`/api/v1/lender/notes/doc/delete/${this.note.id}`, data)
          .then((res) => {
            this.note.docs = res.data.docs;
          })
          .catch(err => {
            this.alertError(err.response.statusText)
          });
      }
    },
    downloadDoc(fileName) {
      window.location.href =
        getBackendUrl() + `/api/v1/lender/notes/doc/download/${fileName}`;
    },
    selectDocs() {
      document.getElementById("docs").click();
    }
  },
  mounted() {
    this.editMode = Number.isInteger(this.id);
    this.editMode ? this.getLenderNote() : (this.loading = false);

    this.$nextTick(() => {
      const scrollbar = document.getElementById("modal-editNote-scroll");
      this.ui.ps = new PerfectScrollbar(scrollbar, {
        useBothWheelAxes: false,
        suppressScrollX: true
      });
    });
  },
  updated() {
    this.ui.ps = null;
    this.$nextTick(() => {
      const scrollbar = document.getElementById("modal-editNote-scroll");
      this.ui.ps = new PerfectScrollbar(scrollbar, {
        useBothWheelAxes: false,
        suppressScrollX: true
      });
    });
  }
};
</script>

<style lang="scss" scoped>
#modal-editNote-scroll {
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;

  .form-group {
    label {
      span {
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }

  .tags__suggested {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 9px;

    &--item {
      display: inline-block;
      color: #0056ff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      margin-left: 3px;
      cursor: pointer;

      &:first-child {
        margin-left: 5px;
      }
    }
  }

  &::v-deep .multiselect {
    &.multiselect-tags {
      min-height: 71px;

      .multiselect__tags {
        border: 1px solid #d4d4d4;
        border-radius: 8px;
        background-color: #F3F3F3;
        min-height: 71px;
      }

      .multiselect__input {
        background-color: #F3F3F3;
      }

      .multiselect__tag {
        display: inline-flex;
        width: fit-content;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.1);

        span {
          color: #000000;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 21px;
        }

        .multiselect__tag-icon {
          position: initial;
          display: flex;
          align-items: center;
          width: fit-content;
          &::after {
            color: #000000;
            font-size: 20px;
            font-weight: normal;
          }

          &:hover {
            background: none !important ;
          }
        }
      }
    }
  }

  .list-group {
    border: none;

    &-item {
      padding-left: 0;
      border: none;

      label {
        display: inline-block;
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
        margin: 0 0 0 11px;
      }

      input {
        height: 18px;
        width: 18px;
        max-width: 18px;
        margin-top: 0;

        &:checked {
          background-color: #0d6efd;
          border-color: #0d6efd;
        }
      }
    }
  }
}

.form-switch {
  margin: 0;

  label {
    opacity: 0.46;
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;

    &.active {
      color: rgba(0, 0, 0, 1);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      opacity: 1;
    }
  }

  input {
    width: 36px !important;
  }
}
</style>
