<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>DSCR Lender Licensing</h3>
        <button class="btn btn-action" @click="$emit('close')">
          <img src="@/assets/icons/icon-close.svg" alt="" />
        </button>
      </div>
      <div class="modal-body">
        <multiselect
          v-model="state"
          class="full-width custom-select"
          open-direction="bottom"
          :options="states"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          @select="filterByState"
          placeholder="Select state to see which DSCR lenders are available"/>
        <table class="table p-2">
          <thead>
          <tr>
            <th scope="col">Lender</th>
            <th scope="col">Notes</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(lender, index) in lenders" :key="index">
            <td @click="openDetails(lender.id)" class="cursor-pointer">{{ lender.name }}</td>
            <td v-if="lender.stateNote && lender.stateNote.length > 30" :content="lender.stateNote" v-tippy="{ placement : 'bottom', arrow: true }">
              {{ lender.stateNote.substring(0, 30) }} ...
            </td>
            <td v-else>{{ lender.stateNote }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "RequestLenderModal",
  components: {
    Multiselect
  },
  data() {
    return {
      isLoading: true,
      state: null,
      states: [],
      lenders: [],
      lenderStates: []
    }
  },
  methods: {
    getData() {
      this.$http.get('/api/v1/lenders/filter-by-state')
        .then((res) => {
          this.states = res.data.states
          this.lenders = []
          this.lenderStates = res.data.lenderStates
          this.isLoading = false
        })
    },
    filterByState(state) {
      let formData = new FormData()
      formData.append('state', state)
      this.$http.post('/api/v1/lenders/filter-by-state', formData)
        .then((res) => {
          this.states = res.data.states
          this.lenders = res.data.lenders
          this.lenderStates = res.data.lenderStates
          this.isLoading = false

          this.lenders.map((el) => {
            el.stateNote = this.lenderNote(el.id)
            return el
          })
        })
    },
    openDetails(lenderId) {
      this.$emit('close')
      this.$emit('openLender', lenderId)
    },
    lenderNote(lenderID) {
      let lenderNote = '';
      this.lenderStates.filter((el) => {
        if (el.lender_id === lenderID) {
          lenderNote = el.note
        }
      })

      return lenderNote;
    }
  },
  beforeMount() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.message {
  text-align: center;
  margin-top: 10px;
}
.modal.modal-center {
  .modal-block {
    margin: 3vh auto 3vh auto;
    overflow-y: auto;
    border-radius: 8px;
  }
  .modal-footer {
    bottom: unset;
  }
  label {
    margin-bottom: .3rem;
  }
  input, textarea {
    background-color: #F3F3F4;
  }
}
</style>
