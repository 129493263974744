<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block" v-show="loading === true">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-block" v-show="loading === false">
      <div class="modal-header">
        <h3>Lender Notes</h3>
        <base-button title="Add Note" action="secondary-default" size="sm" @click-btn="$emit('add-lender-note', id, null)" v-if="permissions.add" />
      </div>
      <div class="modal-body" id="modal-note-scroll">
        <div class="modal-0body-container pb-2">
          <div class="row">
            <div class="col-12" v-if="lenderNotes.length === 0 && !loading">
              <div class="modal-alert modal-alert_advice text-center">
                No Notes Found
              </div>
            </div>
            <div class="col-12" v-else>
              <div class="notes-list">
                <div
                  class="note-list note-click mt-3"
                  v-for="(note, index) in lenderNotes"
                  :key="index"
                >
                  <div class="d-flex">
                    <img
                      :src="agentPhoto(note.agent.photo)"
                      class="avatar"
                      alt="Avatar"
                      v-if="note.agent"
                    />
                    <div class="w-100 note-body">
                      <div
                        class="task-header d-flex justify-content-between"
                        v-if="note.agent"
                      >
                        <span class="task-header-assigner">
                          {{ note.agent.fullName }}
                        </span>
                        <div class="d-flex align-items-center">
                          <button
                            v-if="note.isEdit"
                            class="btn btn-control mx-3"
                            @click="$emit('add-lender-note', id, note)"
                          >
                            <img
                              src="@/assets/icons/icon-edit-normal.svg"
                              alt="Edit"
                            />
                          </button>
                          <span class="task-date">{{ note.createdAt }}</span>
                        </div>
                      </div>
                      <div class="app-updated">
                        <div
                          class="d-flex align-items-center">
                          <div class="label">Lender sends</div>
                          <div class="labelValue"> {{ note.lender_sends ? note.lender_sends : "N/A" }} </div>
                        </div>

                        <div
                          class="d-flex align-items-center">
                          <div class="label">CMC sends</div>
                          <div class="labelValue"> {{ note.cmc_sends ? note.cmc_sends : "N/A" }} </div>
                        </div>

                        <div
                          class="d-flex align-items-center">
                          <div class="label">Note</div>
                          <div class="labelValue"> {{ note.note }} </div>
                        </div>

                        <div class="modal-body-container border-0 my-1 py-0" v-if="note.docs.length > 0">
                          <div class="task-header-assigner my-1">
                            Attached Documents
                          </div>
                          <div
                            class="attach-file mb-2 cursor-pointer"
                            v-for="(doc, index) in note.docs"
                            :key="index"
                            @click="downloadDoc(doc.clientFileName)"
                          >
                            <div>
                              <img
                                src="@/assets/icons/icon-attachment-18.svg"
                                class="opacity-05"
                                alt="Attach"
                              />
                              <span class="file-name">{{
                                doc.orignalFileName
                              }}</span>
                            </div>
                            <img
                              src="@/assets/icons/icon-download.svg"
                              class="opacity-05"
                              alt="Download"
                              @click="downloadDoc(doc.clientFileName)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import { getBackendUrl } from "@/utils/backendUrl";
import BaseButton from '../../components/BaseButton.vue';
export default {
  components: { BaseButton },
  name: "NoteForm",
  props: { id: { required: false } },
  data() {
    return {
      editMode: false,
      loading: true,
      submitting: false,
      lenderNotes: [],
      permissions: {
        add: false
      },
      ui: {
        ps: null
      },
      errors: []
    };
  },
  methods: {
    getLenderNotes() {
      this.$http.get(`/api/v1/lender/notes/${this.id}`).then(res => {
        this.lenderNotes = res.data.data;
        this.permissions = res.data.permissions;
      }).finally(() => {
        this.loading = false;
      });
    },
    downloadDoc(fileName) {
      window.location.href =
        getBackendUrl() + `/api/v1/lender/notes/doc/download/${fileName}`;
    }
  },
  mounted() {
    this.editMode = Number.isInteger(this.id);
    this.editMode ? this.getLenderNotes() : (this.loading = false);

    this.$nextTick(() => {
      const scrollbar = document.getElementById("modal-note-scroll");
      this.ui.ps = new PerfectScrollbar(scrollbar, {
        useBothWheelAxes: false,
        suppressScrollX: true
      });
    });
  },
  updated() {
    this.ui.ps = null;
    this.$nextTick(() => {
      const scrollbar = document.getElementById("modal-note-scroll");
      this.ui.ps = new PerfectScrollbar(scrollbar, {
        useBothWheelAxes: false,
        suppressScrollX: true
      });
    });
  }
};
</script>

<style lang="scss" scoped>
#modal-note-scroll {
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
}

.modal .modal-body-container,
.modal .modal__body-container {
  border-bottom: 0px solid #e7e8e8;
}

.app-updated {
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  padding: 10px 12px;
  margin-top: 5px;

  div {
    width: 100%;
    padding:0.1rem;

    &.label {
      width:10rem;
      opacity: 1;
      color: rgba(0, 0, 0, 1);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
    }

    &.labelValue {
      opacity: 0.46;
      color: rgba(0, 0, 0, 1);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
    }
  }
}

.avatar {
  height: 36px;
  width: 36px;
  border: 1px solid rgba(237, 237, 238, 1);
  border-radius: 50%;
}

.note-body {
  padding: 0 12px 20px 12px;
  border-bottom: 1px solid rgba(231, 232, 232, 1);
}

.task-header-assigner {
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.task-date {
  opacity: 0.46;
  color: rgba(0, 0, 0, 1);
  font-size: 10px;
  letter-spacing: 0;
  line-height: 16px;
}

.assigned-task {
  white-space: break-spaces;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0;
}

.task-body {
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  padding: 9px;
  min-height: 60px;
  margin-top: 9px;
}

.task-info-name {
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
}

.task-comments img {
  opacity: 0.46;
}

.task-comments span {
  color: rgba(138, 138, 138, 1);
  font-size: 11px;
  letter-spacing: 0;
  line-height: 11px;
}

.update-task {
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 7px;
}

.bg-primary {
  height: 36px;
  max-width: 180px;
  width: 100%;
}

.notes-field {
  button {
    min-width: 220px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;

    background-color: #0d6efd !important;
  }
}
</style>
