<template>
  <div class="modal modal-center" id="export">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block">
      <div class="modal-header">
        <h3>Export Report in CSV</h3>
        <div class="modal-close cursor-pointer" @click="$emit('close')">
          <img src="@/assets/icons/icon-remove.svg" alt="Close modal" />
        </div>
      </div>
      <div class="modal-body">
        <div class="modal-body-container border-bottom-0">
          <div class="row">
            <div class="col">
              <div class="form-group full-width">
                <label class="mb-1">Date Range</label>
                <div class="w-100">
                  <date-range-picker
                    ref="picker"
                    v-model="dateRange"
                    class="daterange-dashboard"
                    opens="center"
                    single-date-picker="range"
                    :timePicker="false"
                    :locale-data="{
                      firstDay: 1,
                      format: 'DD-MM-YYYY HH:mm:ss'
                    }"
                  >
                    <template v-slot:input="picker">
                      {{ picker.startDate | date }} -
                      {{ picker.endDate | date }}
                      <img
                        src="@/assets/icons/icon-calender.svg"
                        class="position-absolute"
                        alt=""
                      />
                    </template>
                  </date-range-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button type="submit" @click="exportFile" class="btn bg-primary submit">
          <span
            v-if="ui.btnLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          >
          </span>
          <span v-if="!ui.btnLoading">Export</span>
        </button>
        <button type="reset" class="btn btn-cancel" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";

export default {
  name: "Export",
  components: { DateRangePicker },
  data() {
    return {
      ui: {
        btnLoading: false
      },
      dateRange: {
        startDate: moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
      }
    };
  },
  methods: {
    exportFile() {
      this.ui.btnLoading = true;
      let startDate = moment(this.dateRange.startDate), endDate = moment(this.dateRange.endDate);

      const formData = { 
        startDate: startDate.format("YYYY-MM-DD"), 
        endDate: endDate.format("YYYY-MM-DD") 
      };

      this.$http
        .get(`/api/v1/lenders/export/report`, { params: formData })
        .then(res => {
          if (res.data) {
            let fileURL = window.URL.createObjectURL(new Blob([res.data])),
              fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", `Lender_Partners_(${startDate.format("MMM DD, YYYY")} - ${endDate.format("MMM DD, YYYY")}).csv`);
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
          } else {
            this.alertError('No records found for these daterange.');  
          }
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.btnLoading = false;
        });
    }
  },
  filters: {
    date(date) {
      return moment(String(date)).format("MMM DD, YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  .modal-block {
    border-radius: 8px;
    width: 450px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: initial;
    right: auto;
  }
  .modal-body {
    min-height: 12rem;
    padding: 30px;
    .r-2 {
      right: 1rem !important;
    }
  }
  .modal-header {
    position: relative;
  }
  .modal-footer {
    position: absolute;
  }
}

.check-block {
  display: flex;
  align-items: center;
  border: 1px solid rgba(231, 232, 232, 1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  padding: 12px 12px;
  width: fit-content;

  &.active {
    border: 1px solid rgba(1, 122, 255, 1);
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }

  label {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    margin: 0 0 0 12px;
    white-space: nowrap;
    cursor: pointer;
  }

  .form-check-input {
    height: 18px;
    max-width: 18px;
    margin: 0;
    cursor: pointer;

    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }
}
::v-deep .daterange-dashboard {
  max-width: 100%;
  .reportrange-text {
    line-height: 22px !important;
    max-width: 100%;
  }
  .single.show-ranges .drp-calendar.left {
    border: none !important;
  }
}
.daterange-dashboard {
  &::v-deep .daterangepicker {
    min-width: 385px !important;
  }

  &::v-deep {
    .ranges {
      @media (max-width: 568px) {
        widht: 30%;
      }
    }
  }
}
</style>
