<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block" v-if="ui.loading === true">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-block" v-else>
      <div class="modal-header d-block">
        <h3>Scenario Wizard</h3>
        <p class="mb-0">Send your scenario to multiple lenders at once</p>
      </div>
      <div class="modal-body" id="modal-blastScenario-scroll">
        <div class="modal-body-container pb-2">
          <div class="modal-body-container_title">
            What is the Scenario?
          </div>
          <div class="row mb-4">
            <div class="col-md-12 mb-4">
              <label for="loanAmount">Scenario name</label>
              <input id="loanAmount" type="text" class="form-control" v-model="form.scenarioName"/>
              <span class="error-msg" v-for="(error, index) in getError('scenarioName', errors)" :key="index">
                {{ error }}
              </span>
            </div>
            <div class="col-md-3">
              <label for="loanAmount">Loan Amount</label>
              <div class="input-with-icon mb-2">
                <span class="icon">$</span>
                <input
                  id="loanAmount"
                  type="number"
                  class="form-control"
                  v-model="form.loanAmount"
                  step="0.01"
                />
              </div>
              <span
                class="error-msg"
                v-for="(error, index) in getError('loanAmount', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
            <div class="col-md-3">
              <label for="propertyValue">Property Value</label>
              <div class="input-with-icon mb-2">
                <span class="icon">$</span>
                <input
                  id="propertyValue"
                  type="number"
                  class="form-control"
                  v-model="form.propertyValue"
                  step="0.01"
                />
              </div>
              <span class="error-msg" v-for="(error, index) in getError('propertyValue', errors)" :key="index">
                {{ error }}
              </span>
            </div>
            <div class="col-md-6">
              <label for="product">Product Requested</label>
              <input
                id="product"
                type="text"
                class="form-control"
                v-model="form.product"
              />
              <span
                class="error-msg"
                v-for="(error, index) in getError('product', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-6">
              <label for="loanPurpose">Loan Purpose</label>
              <input id="loanPurpose" type="text" class="form-control" v-model="form.loanPurpose"/>
              <span class="error-msg" v-for="(error, index) in getError('loanPurpose', errors)" :key="index">
                {{ error }}
              </span>
            </div>
            <div class="col-md-6">
              <label for="occupancy">Occupancy</label>
              <input id="occupancy" type="text" class="form-control" v-model="form.occupancy"/>
              <span class="error-msg" v-for="(error, index) in getError('occupancy', errors)" :key="index">
                {{ error }}
              </span>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-6">
              <label for="propertyType">Property Type</label>
              <input
                id="propertyType"
                type="text"
                class="form-control"
                v-model="form.propertyType"
              />
              <span class="error-msg" v-for="(error, index) in getError('propertyType', errors)" :key="index">
                {{ error }}
              </span>
            </div>
            <div class="col-md-6">
              <label for="incomeType">Income Type</label>
              <input
                id="incomeType"
                type="text"
                class="form-control"
                v-model="form.incomeType"
              />
              <span
                class="error-msg"
                v-for="(error, index) in getError('incomeType', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-6">
              <label for="incomeType">Credit</label>
              <input
                id="incomeType"
                type="text"
                class="form-control"
                v-model="form.credit"
              />
              <span
                class="error-msg"
                v-for="(error, index) in getError('Credit', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
            <div class="col-md-6">
              <label for="incomeType">Assets</label>
              <input
                id="incomeType"
                type="text"
                class="form-control"
                v-model="form.assets"
              />
              <span
                class="error-msg"
                v-for="(error, index) in getError('Assets', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-12">
              <label for="lenders">State</label>
              <multiselect v-model="form.state"
                           class="full-width"
                           placeholder="Select state"
                           :options="states"
                           :searchable="true"
                           :allow-empty="false">
              </multiselect>
              <span class="error-msg" v-for="(error, index) in getError('lenders', errors)" :key="index">
                {{ error }}
              </span>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-12">
              <label for="notes" class="mb-0">
                Notes
              </label>
              <p class="text-muted mb-2">
                Explain all the details here-  Include as much detail as possible – include known issues and specific concerns on this file.  It is usually also useful to explain if its already denied somewhere and why.
              </p>
              <ckeditor
                :editor="editor"
                v-model="form.notes"
                id="notes"
                :config="editorConfig"
                required
              />
              <span
                class="error-msg"
                v-for="(error, index) in getError('notes', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-12">
              <label for="lenders">Lenders</label>
              <multiselect v-model="selectedLender"
                             class="full-width"
                             placeholder="Select the Lenders You Want to Send Your Scenario To"
                             :options="lenderOptions"
                             :close-on-select="false"
                             :clear-on-select="false"
                             :preserve-search="true"
                             :searchable="true"
                             :multiple="true"
                             :allow-empty="false"
                             :show-labels="false"
                             group-values="values"
                             group-label="allLabel"
                             :group-select="true"
                             track-by="id"
                             label="name">
                              <template slot="selection" slot-scope="{ values }">
                                <span class="multiselect__single" v-if="values.length === 1">
                                  {{ values[0].name }}
                                </span>
                                <span class="multiselect__single" v-if="values.length > 1">
                                  {{ values.length }} lenders selected
                                </span>
                              </template>
                             :multiple="true"
                             :allow-empty="false"
                </multiselect>
              <span
                class="error-msg"
                v-for="(error, index) in getError('lenders', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <base-button title="Submit" action="secondary-default" :disabled="ui.submitting" :loading="ui.submitting" @click-btn="sendEmail" />
        <base-button title="Discard" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import Multiselect from "vue-multiselect";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: "BlastScenario",
  props: { lenders: { required: false }, states: {required: true} },
  components: { Multiselect, BaseButton },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {},
      formData: new FormData(),
      selectedLender: [],
      lenderOptions: [],
      stateOptions: [],
      ui: {
        ps: null,
        submitting: false,
        loading: false
      },
      form: {
        scenarioName: null,
        loanAmount: null,
        propertyValue: null,
        product: null,
        state: null,
        propertyType: null,
        loanPurpose: null,
        occupancy: null,
        incomeType: null,
        notes: null,
        credit: null,
        assets: null
      },
      errors: [],
      officers: []
    };
  },
  methods: {
    getAgents() {
      this.ui.loading = true;
      this.$http
        .get('/api/v1/form/agents-active')
        .then((res) => {
          this.officers = res.data;
        })
        .finally(() => {
          this.form.officer = {
            id: this.$store.getters.getUserId,
            fullName: this.$store.getters.getFullName,
            email: this.$store.getters.getEmail,
            phone: this.$store.getters.getPhone,
            nmls: this.$store.getters.getNmls
          }
          this.ui.loading = false
        })
        .catch(err => {
          this.alertError(err.response.statusText)
        });
    },
    sendEmail() {
      this.ui.submitting = true;
      this.errors = [];

      if (this.selectedLender.length) {
        this.selectedLender.forEach(lender => {
          this.formData.append("lenders[]", lender.id);
        });
      }

      this.formData.append("scenarioName", this.form.scenarioName);
      this.formData.append("loanAmount", this.form.loanAmount);
      this.formData.append("propertyValue", this.form.propertyValue);
      this.formData.append("loanPurpose", this.form.loanPurpose);
      this.formData.append("occupancy", this.form.occupancy);
      this.formData.append("product", this.form.product);
      this.formData.append("propertyType", this.form.propertyType);
      this.formData.append("incomeType", this.form.incomeType);
      this.formData.append("notes", this.form.notes);
      this.formData.append("credit", this.form.credit);
      this.formData.append("assets", this.form.assets);
      this.formData.append("state", this.form.state);

      this.$http
        .post('/api/v1/lenders/blast-scenario/send-email', this.formData)
        .then((res) => {
          this.alertSuccess(res.data.message);
          this.$emit("close");
        })
        .finally(() => (this.ui.submitting = false))
        .catch(err => {
          let errorMessage = err.response.statusText;
          if (err.response.status === 400) {
            this.formData = new FormData()
            errorMessage = err.response.data.message
            this.errors = err.response.data.errors;
          }

          this.alertError(errorMessage)
        });
    }
  },
  watch: {
    'form.officer'(val) {
      if (val) {
        this.form.email = val.email;
        this.form.phone = val.phone;
        this.form.nmls = val.nmls;
        this.form.name = val.fullName;
      }
    }
  },
  mounted() {
    this.getAgents();
    this.$nextTick(() => {
      const scrollbar = document.getElementById("modal-blastScenario-scroll");
      this.ui.ps = new PerfectScrollbar(scrollbar, {
        useBothWheelAxes: false,
        suppressScrollX: true
      });
    });
  },
  created() {
    this.lenderOptions = [{
      allLabel: 'Select all lenders',
      values: this.lenders
    }]
    this.selectedLender = this.lenders.filter(el => el.isChecked === true);
  },
  updated() {
    this.ui.ps = null;
    this.$nextTick(() => {
      const scrollbar = document.getElementById("modal-blastScenario-scroll");
      this.ui.ps = new PerfectScrollbar(scrollbar, {
        useBothWheelAxes: false,
        suppressScrollX: true
      });
    });
  }
};
</script>

<style lang="scss" scoped>
#modal-blastScenario-scroll {
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;

  .form-group {
    label {
      span {
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }

}

::v-deep .ck-editor .ck-editor__main .ck-content {
  min-height: 200px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
::v-deep .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
::v-deep .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.modal .modal-body-container, .modal .modal__body-container {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    border-bottom: 0px !important;
}

.modal .modal-body, .modal .modal__body {
    padding:30px !important;
    padding-top: 110px !important;
}

::v-deep .multiselect__option--selected.multiselect__option--highlight {
	background: #0d6efd;
	color: #fff
}

::v-deep .multiselect__option--selected.multiselect__option--highlight:after {
	background: #0d6efd;
	content: attr(data-deselect);
	color: #fff
}

::v-deep .multiselect__tag {
  background: #0d6efd;
}

::v-deep i.multiselect__tag-icon {
    background: #0d6efd;
    color: white;
}

::v-deep .multiselect__tag-icon:after {
    color: white;
}
</style>
