<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block" v-show="loading === true">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-block" v-show="loading === false">
      <div class="modal-header">
        <h3>{{ editMode ? "Edit Lender" : "Add new Lender" }}</h3>
      </div>
      <div class="modal-body" id="modal-editlender-scroll">
        <div class="modal-body-container pb-2">
          <div class="row mb-4">
            <div class="col-md-6">
              <label for="name">Lender</label>
              <input
                v-model="lender.name"
                v-input-filled:value="lender.name"
                type="text"
                class="form-control"
                id="name"
                placeholder="Lender name"
                autocomplete="off"
              />
              <span
                class="error-msg"
                v-for="(error, index) in getError('name', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
            <div class="col-md-3">
              <label for="shortNameField">Short Name</label>
              <input type="text" id="shortNameField" class="form-control" />
            </div>
            <div class="col-md-3">
              <label for="comp">Comp.</label>
              <div class="input-with-icon">
                <span class="icon">%</span>
                <input
                  type="number"
                  class="form-control"
                  id="comp"
                  step="0.1"
                  v-input-filled:value="lender.lenderComp"
                  v-model="lender.lenderComp"
                />
              </div>
              <span
                class="error-msg"
                v-for="(error, index) in getError('lenderComp', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-6">
              <label for="comp-min">Comp Minimum</label>
              <div class="input-with-icon">
                <span class="icon">$</span>
                <input
                  type="number"
                  class="form-control"
                  id="comp-min"
                  step="1"
                  v-input-filled:value="lender.compMin"
                  v-model="lender.compMin"
                />
              </div>
              <span
                class="error-msg"
                v-for="(error, index) in getError('compMin', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
            <div class="col-md-6">
              <label for="comp-max">Comp Maximum</label>
              <div class="input-with-icon">
                <span class="icon">$</span>
                <input
                  type="number"
                  class="form-control"
                  id="comp-max"
                  step="1"
                  v-input-filled:value="lender.compMax"
                  v-model="lender.compMax"
                />
              </div>
              <span
                class="error-msg"
                v-for="(error, index) in getError('compMax', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
          </div>
          <div class="row mt-4">
            <div class="mb-3">
              <div class="d-flex">
                <div class="form-check form-switch p-0">
                  <label
                    class="form-check-label ms-0 me-3"
                    :class="{ active: lender.active === false }"
                    for="fieldIsActive"
                    >Inactive</label
                  >
                  <input
                    class="form-check-input m-0"
                    type="checkbox"
                    id="fieldIsActive"
                    v-model="lender.active"
                  />
                  <label
                    class="form-check-label ms-3"
                    :class="{ active: lender.active === true }"
                    for="fieldIsActive"
                    >Active</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body-container">
          <div class="mb-4">
            <div class="form-group full-width">
              <label for="loginUrl">Login URL</label>
              <input
                type="url"
                id="loginUrl"
                v-model="lender.loginUrl"
                v-input-filled:value="lender.loginUrl"
                class="form-control"
                placeholder="https://www.website.com"
                autocomplete="off"
              />
              <span
                class="error-msg"
                v-for="(error, index) in getError('loginUrl', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
          </div>
          <div class="mb-4">
            <div class="form-group full-width">
              <label for="amcUrl">AMC URL</label>
              <input
                type="url"
                id="amcUrl"
                v-model="lender.amcUrl"
                v-input-filled:value="lender.amcUrl"
                placeholder="AMC URL"
                class="form-control full-width"
                autocomplete="off"
              />
              <span
                class="error-msg"
                v-for="(error, index) in getError('amcUrl', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
          </div>
        </div>
        <div class="modal-body-container">
          <div class="mb-4">
            <div class="form-group full-width">
              <label for="note">Note</label>
              <textarea
                type="url"
                rows="5"
                id="note"
                v-model="lender.note"
                v-input-filled:value="lender.note"
                placeholder="Write note"
                class="form-control full-width"
                autocomplete="off"
              ></textarea>
              <span
                class="error-msg"
                v-for="(error, index) in getError('notes', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
          </div>
        </div>
        <div class="modal-body-container">
          <div class="modal-body-container_title">Tags</div>
          <div class="form-group full-width">
            <label for="">Add Tags <span>(maximum 10)</span></label>
            <multiselect
              v-model="selectedTags"
              tag-placeholder="Add this as new tag"
              @tag="addNewTag"
              placeholder="Search or add a tag"
              label="name"
              class="w-100 full-width multiselect-tags"
              track-by="id"
              :options="tags"
              :multiple="true"
              :taggable="true"
              :max="10"
            >
            </multiselect>
            <span
              class="error-msg mt-2"
              v-for="(error, index) in getError('tags', errors)"
              :key="index"
            >
              {{ error }}
            </span>
          </div>
          <div class="tags__suggested">
            Suggested:
            <span
              class="tags__suggested--item"
              v-for="tag in getSuggestedTags()"
              @click="tagSuggested(tag)"
              :key="tag.id"
              >{{ tag.name }},
            </span>
          </div>
        </div>
        <div class="modal-body-container">
          <div class="modal-body-container_title">Contact</div>
          <div class="row mb-4">
            <div class="form-group col-md-6">
              <label for="repName">Rep. name</label>
              <input
                id="repName"
                class="form-control"
                v-model="lender.repName"
                v-input-filled:value="lender.repName"
                placeholder="Enter Rep. Name"
                autocomplete="off"
              />
              <span
                class="error-msg"
                v-for="(error, index) in getError('repName', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
            <div class="form-group col-md-6">
              <label for="repDesignation">Rep. Designation</label>
              <input
                id="repDesignation"
                class="form-control"
                v-model="lender.repDesignation"
                v-input-filled:value="lender.repDesignation"
                placeholder="Optional"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="form-group col-md-6">
              <label for="repPhone">Rep. Phone</label>
              <input
                type="text"
                id="repPhone"
                class="form-control"
                v-model="lender.repPhone"
                v-input-filled:value="lender.repPhone"
                placeholder="Enter Rep. Phone"
                autocomplete="off"
              />
              <span
                class="error-msg"
                v-for="(error, index) in getError('repPhone', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
            <div class="form-group col-md-6">
              <label for="repEmail">Rep. Email</label>
              <input
                type="text"
                id="repEmail"
                class="form-control"
                v-model="lender.repEmail"
                v-input-filled:value="lender.repEmail"
                placeholder="Enter Rep. Email"
                autocomplete="off"
              />
              <span
                class="error-msg"
                v-for="(error, index) in getError('repEmail', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
          </div>
          <div class="row mb-4">
            <div class="form-group">
              <label for="licenseNumber">License Number</label>
              <input
                type="text"
                id="licenseNumber"
                class="form-control"
                v-model="lender.licenseNumber"
                v-input-filled:value="lender.licenseNumber"
                placeholder="Enter License Number"
                autocomplete="off"
              />
              <span
                class="error-msg"
                v-for="(error, index) in getError('licenseNumber', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
          </div>
          <div class="row mb-4">
            <div class="form-group full-width">
              <label for="address">Address</label>
              <textarea
                type="text"
                rows="5"
                id="address"
                v-model="lender.address"
                v-input-filled:value="lender.address"
                placeholder="Enter Address.."
                class="form-control full-width"
                autocomplete="off"
              ></textarea>
              <span
                class="error-msg"
                v-for="(error, index) in getError('address', errors)"
                :key="index"
              >
                {{ error }}
              </span>
            </div>
          </div>
        </div>
        <div class="modal-body-container border-0">
          <div class="modal-body-container_title">Instructions</div>
          <file-field-new :files="scannedDoc" field-id="new-funding-scanned-doc"
                          @change="handleFile" />
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <base-button title="Save" action="secondary-default" :loading="submitting" @click-btn="storeLender" :disabled="submitting" />
        <base-button title="Discard" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import Multiselect from "vue-multiselect";
import { getBackendUrl } from "@/utils/backendUrl";
import BaseButton from '../../components/BaseButton.vue';
import FileFieldNew from "../../components/FileFieldNew.vue";

export default {
  name: "NewLender",
  props: { id: { required: false } },
  components: {FileFieldNew, Multiselect, BaseButton },
  data() {
    return {
      formData: new FormData(),
      editMode: false,
      loading: true,
      submitting: false,
      lender: {
        name: "",
        lenderComp: 0.1,
        compMax: 0,
        compMin: 0,
        active: "",
        loginUrl: "",
        amcUrl: "",
        note: "",
        repName: "",
        repDesignation: "",
        repPhone: "",
        repEmail: "",
        licenseNumber: "",
        address: "",
        docs: [],
        tags: []
      },
      ui: {
        ps: null
      },
      selectedDocsCount: 0,
      errors: [],
      selectedTags: [],
      tags: [],
      scannedDoc: []
    };
  },
  methods: {
    storeLender() {
      this.submitting = true;
      this.errors = [];
      let lender = this.lender;

      for (let key in lender) {
        if (key !== "docs" && key !== "tags") {
          this.formData.append(key, lender[key]);
        }
      }

      this.selectedTags.forEach(tag => {
        this.formData.append("tags[]", tag.id);
      });

      let url = Number.isInteger(this.id)
        ? "/api/v1/lenders/edit/" + this.id
        : "/api/v1/lenders/new";

      this.$http
        .post(url, this.formData)
        .then(() => {
          this.$emit("close", true);
        })
        .finally(() => (this.submitting = false))
        .catch(err => {
          let errorMessage = err.response.statusText;
          if (err.response.status === 400) {
            this.formData = new FormData()
            errorMessage = err.response.data.message
            this.errors = err.response.data.errors;
          }

          this.alertError(errorMessage)
        });
    },
    getTags() {
      this.$http.get(`/api/v1/tags`).then(res => {
        this.tags = res.data;
      });
    },
    handleFile(files) {
      let length = files.length;
      this.selectedDocsCount = length;
      console.log(files)
      for (let i = 0; i < length; i++) {
        this.formData.append("docs[]", files[i]);
      }

      this.scannedDoc = files;
    },
    getLender() {
      this.$http.get(`/api/v1/lenders/show/${this.id}`).then(res => {
        this.selectedTags = res.data.tags;
        this.lender = res.data;
        this.loading = false;
      });
    },
    deleteDoc(id) {
      if (confirm("Are you sure?")) {
        this.$http.delete(`/api/v1/lenders/doc/delete/${id}`).then(() => {
          this.lender.docs = this.lender.docs.filter(doc => {
            return doc.id !== id;
          });
        });
      }
    },
    downloadDoc(id) {
      window.location.href =
        getBackendUrl() + `/api/v1/lenders/doc/download/${id}`;
    },
    addNewTag(name) {
      let formData = new FormData();
      formData.append("name", name);
      this.$http.post(`/api/v1/tag/new`, formData).then(res => {
        this.tags.push(res.data);
        this.selectedTags.push(res.data);
      });
    },
    selectDocs() {
      document.getElementById("docs").click();
    },
    getSuggestedTags() {
      return this.tags.filter(tag => {
        return tag.id <= 5;
      });
    },
    tagSuggested(tag) {
      if (this.selectedTags.length < 5) {
        !this.selectedTags.find(el => el.id === tag.id)
        ? this.selectedTags.push(tag)
        : false;
      }
    }
  },
  mounted() {
    this.getTags();
    this.editMode = Number.isInteger(this.id);
    this.editMode ? this.getLender() : (this.loading = false);

    this.$nextTick(() => {
      const scrollbar = document.getElementById("modal-editlender-scroll");
      this.ui.ps = new PerfectScrollbar(scrollbar, {
        useBothWheelAxes: false,
        suppressScrollX: true
      });
    });
  },
  updated() {
    this.ui.ps = null;
    this.$nextTick(() => {
      const scrollbar = document.getElementById("modal-editlender-scroll");
      this.ui.ps = new PerfectScrollbar(scrollbar, {
        useBothWheelAxes: false,
        suppressScrollX: true
      });
    });
  }
};
</script>

<style lang="scss" scoped>
#modal-editlender-scroll {
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;

  .form-group {
    label {
      span {
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }

  .tags__suggested {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 9px;

    &--item {
      display: inline-block;
      color: #0056ff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      margin-left: 3px;
      cursor: pointer;

      &:first-child {
        margin-left: 5px;
      }
    }
  }

  &::v-deep .multiselect {
    height: unset;
    &.multiselect-tags {
      min-height: 71px;

      .multiselect__tags {
        border: 1px solid #d4d4d4;
        border-radius: 8px;
        background-color: #F3F3F3;
        min-height: 71px;
        max-height: unset;
      }

      .multiselect__input {
        background-color: #F3F3F3;
      }

      .multiselect__tag {
        display: inline-flex;
        width: fit-content;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.1);

        span {
          color: #000000;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 21px;
        }

        .multiselect__tag-icon {
          position: initial;
          display: flex;
          align-items: center;
          width: fit-content;
          &::after {
            color: #000000;
            font-size: 20px;
            font-weight: normal;
          }

          &:hover {
            background: none !important ;
          }
        }
      }
    }
  }

  .list-group {
    border: none;

    &-item {
      padding-left: 0;
      border: none;

      label {
        display: inline-block;
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
        margin: 0 0 0 11px;
      }

      input {
        height: 18px;
        width: 18px;
        max-width: 18px;
        margin-top: 0;

        &:checked {
          background-color: #0d6efd;
          border-color: #0d6efd;
        }
      }
    }
  }
}

.form-switch {
  margin: 0;

  label {
    opacity: 0.46;
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;

    &.active {
      color: rgba(0, 0, 0, 1);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      opacity: 1;
    }
  }

  input {
    width: 36px !important;
  }
}
</style>
