<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block" v-show="loading === true">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-block" v-show="loading === false">
      <div class="modal-header d-block">
        <div class="d-flex justify-content-between">
          <h3 class="d-flex align-items-center">
            {{ lender.name }}
            <span class="status" :class="{ status_inactive: !lender.active }">
              {{ lender.active ? "Active" : "Inactive" }}
            </span>
          </h3>
          <button class="btn btn-control" @click="$emit('editLender', lender.id)">
            <img src="@/assets/icons/icon-edit-normal.svg" alt="Edit" />
          </button>
        </div>
      </div>
      <div class="modal-body" id="modal-lender-scroll">
        <div class="modal-body-container mt-3">
          <div class="table">
            <table class="table">
              <tbody>
                <tr>
                  <td>Lender Comp</td>
                  <td>{{ lender.lenderComp }}</td>
                </tr>
                <tr>
                  <td>Comp Minimum</td>
                  <td>{{ convertToCurrency(lender.compMin) }}</td>
                </tr>
                <tr>
                  <td>Comp Maximum</td>
                  <td>{{ convertToCurrency(lender.compMax) }}</td>
                </tr>
                <tr>
                  <td>Login</td>
                  <td>
                    <a :href="lender.loginUrl ? lender.loginUrl : '#'">
                      {{ lender.loginUrl ? lender.loginUrl : "N/A" }}</a>
                  </td>
                </tr>
                <tr>
                  <td>AMC URL</td>
                  <td>
                    <a :href="lender.amcUrl ? lender.amcUrl : '#'">
                      {{ lender.amcUrl ? lender.amcUrl : "N/A" }}</a>
                  </td>
                </tr>
                <tr>
                  <td>Notes</td>
                  <td style="white-space: pre;">{{ lender.note }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-body-container">
          <div class="modal-body-container_title">
            Tags
          </div>
          <div class="modal__filters">
            <div class="modal__filter" v-for="tag in lender.tags" :key="tag.id">
              {{ tag.name }}
            </div>
          </div>
        </div>
        <div class="modal-body-container">
          <div class="modal-body-container_title">Contacts</div>
          <div class="table">
            <table class="table">
              <tbody>
                <tr>
                  <td>Representative</td>
                  <td>{{ lender.repName }}
                  </td>
                </tr>
                <tr>
                  <td>Rep. Designation</td>
                  <td>{{ lender.emailSenderName ? lender.emailSenderName : "N/A" }}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{{ lender.repPhone }}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{{ lender.repEmail }}</td>
                </tr>
                <tr>
                  <td>License Number</td>
                  <td>{{ lender.licenseNumber }}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{{ lender.address }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-body-container border-0">
          <div class="modal-body-container_title">Instructions</div>
          <p class="text-muted">
            {{ lender.instructions ? lender.instructions : "N/A" }}
          </p>
          <div
            class="attach-file mb-2 cursor-pointer"
            v-for="(doc, index) in lender.docs"
            :key="index"
            @click="downloadDoc(doc.id)"
          >
            <div>
              <img
                src="@/assets/icons/icon-attachment-18.svg"
                class="opacity-05"
                alt="Attach"
              />
              <span class="file-name">{{ doc.name }}</span>
            </div>
            <img
              src="@/assets/icons/icon-download.svg"
              class="opacity-05"
              alt="Download"
              @click="downloadDoc(doc.id)"
            />
          </div>
        </div>
        <div class="modal-body-container border-0">
          <div class="modal-body-container_title">DSCR States</div>
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col" width="20%">State</th>
              <th scope="col" width="20%">Do loan</th>
              <th scope="col">Note</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(state, index) in states" :key="index">
              <td>{{ state.state }}</td>
              <td>
                <div class="form-check form-switch">
                  <input class="form-check-input" v-model="state.doLoan" @change="updateState(state)" type="checkbox" id="flexSwitchCheckDefault">
                  <label class="form-check-label" for="flexSwitchCheckDefault">
                    {{ state.doLoan === null ? 'N/A' : state.doLoan === true ? 'Yes' : 'No' }}
                  </label>
                </div>
              </td>
              <td>
                <input v-if="state.isEdit === true" class="form-control form-control-xs" type="text" placeholder="Note" aria-label=".form-control-sm example"
                       v-model="state.note"
                      @blur="updateStateNote(state)">
                <div class="d-flex align-items-center" v-else>
                  <img style="margin-right: 5px"
                       src="@/assets/icons/edit.svg"
                       class="opacity-05"
                       alt="Edit notes" @click="editNote(state.id)"/>
                  <div v-if="state.note && state.note.length > 30" :content="state.note" v-tippy="{ placement : 'bottom', arrow: true }">
                    {{ state.note.substring(0,30) }} ...
                    <span ></span>
                  </div>
                  <div v-else>{{state.note}}</div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-end" v-if="loading === false">
        <img
          src="@/assets/icons/icon-delete.svg"
          class="opacity-05"
          alt="Attach"
          @click="deleteLender"/>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import { getBackendUrl } from "@/utils/backendUrl";

export default {
  name: "DetailsModal",
  props: { id: { type: Number, required: false } },
  data() {
    return {
      loading: true,
      lender: {},
      states: [],
      editLender: false,
      ui: { ps: null }
    };
  },
  methods: {
    getLender() {
      this.$http.get(`/api/v1/lenders/show/${this.id}`).then(res => {
        this.lender = res.data;
        this.states = this.lender.states.map((el) => {
          el.isEdit = false
          return el
        });
        this.loading = false;
      });
    },
    deleteLender() {
      this.$http.get(`/api/v1/lenders/delete/${this.id}`).then(() => {
        this.$emit("close", true);
      });
    },
    downloadDoc(id) {
      window.location.href =
        getBackendUrl() + `/api/v1/lenders/doc/download/${id}`;
    },
    updateState(state) {
      let formData = new FormData()
      formData.append('doLoan', state.doLoan)
      this.$http.post(`/api/v1/lenders/edit/update-state/${state.id}`, formData)
        .then((res) => {
          this.states = this.states.map((el) => {
            if(el.id === state.id) {
              el = res.data
            }
            return el
          })
        })
    },
    editNote(stateId) {
      this.states = this.states.map((el) => {
        el.isEdit = el.id === stateId
        return el
      })
    },
    updateStateNote(state) {
      let formData = new FormData()
      formData.append('note', state.note)
      this.$http.post(`/api/v1/lenders/edit/update-state-note/${state.id}`, formData)
        .then((res) => {
          this.states = this.states.map((el) => {
            if(el.id === state.id) {
              el = res.data
            }
            return el
          })
        })
    }
  },
  created() {
    this.getLender();
  },
  mounted() {
    this.$nextTick(() => {
      const scrollbar = document.getElementById("modal-lender-scroll");
      this.ui.ps = new PerfectScrollbar(scrollbar, {
        useBothWheelAxes: false,
        suppressScrollX: true
      });
    });
  },
  updated() {
    this.ui.ps = null;
    this.$nextTick(() => {
      const scrollbar = document.getElementById("modal-lender-scroll");
      this.ui.ps = new PerfectScrollbar(scrollbar, {
        useBothWheelAxes: false,
        suppressScrollX: true
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__filter {
    padding: 8px 12px;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    border-radius: 2px;
    background-color: rgba(146, 146, 146, 0.1);
    margin: 0 8px 4px 0;
  }
}
#modal-lender-scroll {
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
}
.status {
  margin-left: 10px;
  border: 1px solid #00ab34;
  border-radius: 12px;
  background-color: #00ab34;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding: 4px 11.5px;

  &.status_inactive {
    border: 1px solid #e7e8e8;
    background-color: #f6f6f6;
    color: black;
  }
}
.table {
  tbody {
    tr {
      td {
        min-height: 48px;
        height: 48px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-radius: 0;
        &:first-child {
          width: 35%;
          color: #000000 !important;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          opacity: 1 !important;
        }
        &:last-child {
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
